<template>
  <vx-card title="Reversal Invoice">
    <vs-tabs>
      <vs-tab label="Open">
        <open></open>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <approved></approved>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <rejected></rejected>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open";
import Rejected from "./rejected";
import Approved from "./approved";
export default {
  components: {
    Open,
    Approved,
    Rejected
  },
};
</script>